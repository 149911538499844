<template>
    <div class="pieMain">
    </div>
</template>

<script>
import {getTotal} from '../../../api/index.js'
export default {
    name: '',
    props: {
        syncId: String
        // data: Object
    },
    data() {
        return {
            data: {// 饼图数据1
                title: "",
                color: '#2C7BFE',
                data: [
                // { value: 2, name: '', itemStyle: {color: 'rgba(0,0,0,0)'}, label: {show: false} }, // 非数据源
                //     {
                //         value: 60,
                //         name: '体育',
                //         itemStyle: {
                //             color: '#1456FE'
                //         }

                //     },
                //     { value: 2, name: '', itemStyle: {color: 'rgba(0,0,0,0)'}, label: {show: false} }, // 非数据源

                //     {
                //         value: 20,
                //         name: '文化',
                //         itemStyle: {
                //             color: '#FF4A22'
                //         }
                //     },
                //     { value: 2, name: '', itemStyle: {color: 'rgba(0,0,0,0)'}, label: {show: false} }, // 非数据源

                //     {
                //         value: 20,
                //         name: '教育',
                //         itemStyle: {
                //             color: '#FFCD3E'
                //         }
                //     },
                //     { value: 2, name: '', itemStyle: {color: 'rgba(0,0,0,0)'}, label: {show: false} }, // 非数据源

                //     {
                //         value: 20,
                //         name: '科学',
                //         itemStyle: {
                //             color: '#3EF3FF'
                //         }
                //     },
                ],
            },
            color: ["#1456FE","#FF4A22","#FFCD3E","#3EF3FF"]
        }
    },
    methods: {
        // 交易支出 饼图
        setChart() {
            let option = {
            title: {
                show: false
            },
            tooltip: {
                trigger: "item",
            },
            legend: {
                show: false
            },
            series: [{
                    name: "交易品种收益",
                    type: "pie",
                    radius: ["50%", "65%"],
                    center: ['50%', '50%'],
                    avoidLabelOverlap: false,
                    label: {
                        position: "outside",
                        color: "#E6F7FF",
                        lineHeight: 18,
                        formatter: params => {
                        const { data } = params;
                        return data.name ? `${data.name}\n${data.value}` : "";
                        },
                    },
                    // itemStyle: {
                    //     // borderColor: "transparent",
                    //     borderWidth:500,	//边框的宽度
                    //     borderColor:'rgba(255,255,255,0)'	//边框的颜色
                    // },
                    emphasis: {
                        scaleSize: 5,
                    },
                    itemStyle:{
                        borderColor: "transparent",

                    },
                    data: this.data.data,
                } 
                ],
            };
            let myChart = this.$echarts(this.$el);

            myChart.clear();
            myChart.resize()
            myChart.setOption(option);
        },
    },
    mounted() {
        if (!this.syncId) return
        getTotal({
            syncId: this.syncId
        }).then(res => {
            res.data.forEach(item => {
                this.data.data.push({
                        value: item.value,
                        name: item.name,
                        itemStyle: {
                            color: this.color[Number(item.book_class)]
                        }
                    },
                    { value: item.value/30, name: '', itemStyle: {color: 'rgba(0,0,0,0)'}, label: {show: false} }, // 非数据源
                )
            })
        })
        setTimeout(() => {
          this.setChart()
        }, 500)
    },
}
</script>

<style lang="less" scoped>
.pieMain {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
</style>